import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from 'styled-components/macro';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import { Article, Main } from '../components/Elements';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Flex, Box, Text, Image } from 'rebass';

import Img from 'gatsby-image';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';

const Vuokralainen = ({ data }) => {
  const seo = {
    tags: [
      {
        tagName: 'title',
        content: 'Tietosuojaseloste',
      },
    ],
  };

  return (
    <Fragment>
      <SEO meta={seo} />
      <div className="hamburgerColorBlue">
        <Header logo="white" />
      </div>
      <Main
        bg={'#E0D1B6'}
        css={`
          h1 {
            font-family: 'utopia-std', serif;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            font-size: 42px;
            line-height: 1.34;
            font-weight: 600;
            margin-top: 70px;
            margin-bottom: 40px;
            text-align: center;
            color: #152a38;
          }
          @media (max-width: 600px) {
            h1 {
              font-size: 22px;
            }
          }
          .hero {
            width: 100%;
            height: 270px;
          }
        `}
      >
        <BackgroundImage
          Tag="section"
          className="hero"
          fluid={data.page.image.fluid}
        ></BackgroundImage>
        <h1>{data.page.vuokralainenOtsikko}</h1>
        <div
          css={`
            display: flex;
            position: relative;
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-around;
            align-items: center;
            padding-bottom: 360px;

            @media (max-width: 700px) {
              flex-direction: column;
            }
            > div {
              z-index: 2;
              width: 100%;
              max-width: 400px;
              background: #152a38;
              color: #fff;
              padding: 60px 40px;
              margin-bottom: 40px;
              min-height: 400px;
              @media (max-width: 400px) {
                margin-bottom: 0;
              }
            }
            h3 {
              margin-bottom: 20px;
            }
          `}
        >
          <div>
            <div dangerouslySetInnerHTML={{ __html: data.page.huolto }} />
            <a
              href={data.page.palvelupyyntUrl}
              target="_blank"
              rel="noreferrer"
              css={`
                background: #fff;
                color: #152a38;
                width: 280px;
                height: 69px;
                font-family: 'utopia-std', serif;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: uppercase;
                justify-content: center;
                font-size: 16px;
                margin-top: 50px;
                &:hover {
                  color: #264051;
                }
              `}
            >
              <span>Tee palvelupyyntö</span>
            </a>
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: data.page.vuokranantaja }}
            />
          </div>
          <Image
            width="870px"
            src={data.datoCmsSivusto.tausta1.url}
            alt={data.datoCmsSivusto.tausta1.alt}
            sx={{
              display: ['none', 'block'],
              position: 'absolute',
              bottom: -300,
              right: -300,
            }}
          />
        </div>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

export const vuokralainenQuery = graphql`
  query VuokralainenQuery {
    page: datoCmsContact {
      vuokralainenOtsikko
      huolto
      palvelupyyntUrl
      vuokranantaja
      image {
        fluid(maxWidth: 2100) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
    datoCmsSivusto {
      tausta1 {
        url
        alt
      }
    }
  }
`;

Vuokralainen.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Vuokralainen;
